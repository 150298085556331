import React from 'react'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'
import {StaticImage} from 'gatsby-plugin-image'

const Periodika = ({data}) => {

return(
    <div>
      <MySEO
        title="Periodika"
        description="Periodika von Hasen oder Bein"
      />
    <Heading># blätterbares</Heading>
    <Text></Text>
    <Text><b>Grafische Betreuung von Periodika für Verlage, Universitäten & Co. </b>
    Über Gedrucktes macht man Inhalte wirklich (be)greifbar. Von Planung & Konzeption sowie Layout & Satz über Anzeigenverwaltung
    und -gestaltung bis zur finalen Druckvorstufe ist <b>hasen oder bein</b> erfahren und zuverlässig.
    Selbstverständlich ist es möglich, die Inhalte auch auf die digitale Ebene &mdash;
    z.B. als verlinktes und interaktives Blättermagazin &mdash;
    zu übertragen oder parallel eine Webapplikation zu erstellen.

    </Text>
    <Text>Beispiel &rarr; <a href="http://soziologieblog.hypotheses.org/krisen" target="_blank" rel="noopener noreferrer">SOZIOLOGIEMAGAZIN &mdash; diverse Ausgaben</a>
  </Text>
    <Flex>

    <Box width={["100%","100%","49%"]}>
         <StaticImage alt="" src="../images/tableau2_periodika.jpg" />
    </Box>

       <Box width={["100%","100%","49%"]}>
             <StaticImage alt="" src="../images/tableau1_periodika.jpg" />
       </Box>

     </Flex>
    </div>
)}


  export default Periodika
